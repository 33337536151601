import paths from "~/constants/paths";
export default defineNuxtRouteMiddleware((to) => {
  const user = useCookie("hs-token");

  if (to.path !== paths.login && !user.value) {
    return navigateTo("/login");
  }

  if (to.path === paths.login && user.value) {
    return navigateTo("/");
  }
});
